import { useEffect, useState } from "react";
import { ZodStore } from "./zodstore";

export const useData = <T,>(store: ZodStore) => {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    return store.monitor((data) => {
      setData(data as unknown as T[]);
      setLoading(false);
    });
  }, [store]);

  return {
    data,
    loading,
    save: store.save,
    update: store.update,
    remove: store.remove,
  };
};
