import styled from "@emotion/styled";
import { H4, IconButton } from "@introist/react-foundation/v2";

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

type HeaderProps = {
  date: Date;
  onChangeDate: (date: Date) => void;
};

const changeDate = (date: Date, amount: number) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + amount);
  return newDate;
};

const DateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-large);
`;

export const Header = ({ date, onChangeDate }: HeaderProps) => {
  return (
    <HeaderContainer>
      <IconButton
        icon="chevronLeft"
        onClick={() => onChangeDate(changeDate(date, -1))}
      />
      <DateContainer>
        <IconButton icon="calendar" onClick={() => onChangeDate(new Date())} />

        <H4>
          {date.toLocaleDateString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
          })}
        </H4>
      </DateContainer>

      <IconButton
        icon="chevronRight"
        onClick={() => onChangeDate(changeDate(date, 1))}
      />
    </HeaderContainer>
  );
};
