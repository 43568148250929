import { z } from "zod";
import { v4 as uuid } from "uuid";
import { useData } from "../firebase/use-data";
import { zodStore } from "../firebase/zodstore";

const convertToTaskCriterion = (val: any) => {
  return val.map((cri: any) => {
    if (typeof cri === "string") return { title: cri, completed: false };
    return cri;
  });
};

export const TaskCriterionSchema = z.object({
  title: z.string(),
  completed: z.boolean().default(false),
});
export type TaskCriterion = z.infer<typeof TaskCriterionSchema>;

export const TaskSchema = z.object({
  id: z.string().default(() => uuid()),
  date: z.string(),
  topic: z.string().optional(),
  title: z.string(),
  completed: z.boolean(),
  criteria: z.preprocess(
    convertToTaskCriterion,
    TaskCriterionSchema.array().default([])
  ),
  createdAt: z
    .string()
    .datetime()
    .default(() => new Date().toISOString()),
});
export type Task = z.infer<typeof TaskSchema>;

const store = zodStore(TaskSchema, ["tasks"]);

export const useTasks = () => useData<Task>(store);
