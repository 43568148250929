import React from "react";
import { ThemeProvider } from "@introist/react-foundation/v2";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HomePage } from "./ui/modules/home";
import { DailyPage } from "./ui/modules/daily/DailyPage";
import { WithUser } from "./WithUser";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "daily",
    element: (
      <WithUser>
        <DailyPage />
      </WithUser>
    ),
  },
]);

function App() {
  return (
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
