import { ReactNode } from "react";
import { useUser } from "./data/firebase/use-user";
import { Button, useTheme } from "@introist/react-foundation/v2";
import styled from "@emotion/styled";

type Props = {
  children: ReactNode;
};

const Page = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 64px;
`;

export const AppPage = ({ children }: Props) => {
  const { user, logout } = useUser();
  const { theme } = useTheme();

  return (
    <Page>
      <Header>
        {user && (
          <Button
            variant="blended"
            color={theme.palette.foreground}
            onClick={logout}
          >
            Sign out
          </Button>
        )}
      </Header>
      {children}
    </Page>
  );
};
