import styled from "@emotion/styled";
import {
  IconButton,
  Title,
  useTheme,
  Select,
} from "@introist/react-foundation/v2";
import { Task } from "../../../data/schema";
import { TaskCriteria } from "./TaskCriteria";

const TaskContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);

  :hover .remove-task {
    display: initial !important;
  }
`;

const TaskItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-large);
`;

type TaskItemProps = {
  task: Task;
  onTaskChanged: (task: Task) => void;
  onComplete: () => void;
  onRemove: () => void;
  topics: string[];
};

export const TaskItem = ({
  task,
  onComplete,
  onRemove,
  onTaskChanged,
  topics,
}: TaskItemProps) => {
  const { theme } = useTheme();

  return (
    <TaskContainer>
      <TaskItemContainer>
        <IconButton
          icon={task.completed ? "checkCircle" : "progress"}
          color={task.completed ? theme.palette.success : theme.palette.border}
          onClick={onComplete}
        />
        <Title
          variant="bold"
          color={task.completed ? theme.palette.success.default : undefined}
          onBlur={(e) =>
            onTaskChanged({ ...task, title: e.target.textContent || "" })
          }
          contentEditable={!task.completed}
          style={{
            outline: "none",
            textDecoration: task.completed ? "line-through" : undefined,
          }}
        >
          {task.title}
        </Title>
        <Title
          color={task.completed ? theme.palette.success.default : undefined}
          style={{
            textDecoration: task.completed ? "line-through" : undefined,
          }}
        >
          in
        </Title>
        <Select
          searchable
          element="button"
          variant="blended"
          buttonColor={
            task.completed ? theme.palette.success : theme.palette.border
          }
          size="small"
          placeholder="unstructured tasks"
          options={topics.map((topic) => ({ key: topic }))}
          value={task.topic}
          style={{
            textDecoration: task.completed ? "line-through" : undefined,
          }}
          onEnter={(value) => {
            if (value.length > 0) {
              onTaskChanged({ ...task, topic: value });
            }
          }}
        />
        <div style={{ width: "24px", marginLeft: "auto" }}>
          <IconButton
            icon="trash"
            onClick={onRemove}
            style={{ display: "none" }}
            color={theme.palette.border}
            className="remove-task"
          />
        </div>
      </TaskItemContainer>

      <TaskCriteria
        criteria={task.criteria}
        onCriteriaChanged={(criteria) => onTaskChanged({ ...task, criteria })}
        taskCompleted={task.completed}
      />
    </TaskContainer>
  );
};
