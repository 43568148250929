import { getFirestore, collection } from "firebase/firestore";
import { app, auth } from "./firebase";

export const db = getFirestore(app);

const getCollection = (path: string[]) => {
  const [initial, ...rest] = path;
  return collection(db, initial, ...rest);
};

export const getUserCollection = (path: string[]) => {
  const user = auth.currentUser;
  if (!user) throw new Error("Cannot get user collection without user");
  return getCollection(["users", user.uid, ...path]);
};
