import { app } from "../../../data/firebase/firebase";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import StyledFirebaseAuth from "./StyledFirebaseAuth";
import { Button, Modal } from "@introist/react-foundation/v2";
import { useState } from "react";

const uiConfig = {
  signInFlow: "popup",
  signInSuccessUrl: "/daily",
  signInOptions: [GoogleAuthProvider.PROVIDER_ID],
};

export const SignInButton = () => {
  const [signInOpen, setSignInOpen] = useState(false);

  return (
    <>
      <Button variant="blended" onClick={() => setSignInOpen(true)}>
        Sign In
      </Button>
      <Modal
        title="Sign In"
        open={signInOpen}
        onClose={() => setSignInOpen(false)}
        style={{ height: "400px" }}
      >
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth(app)} />
      </Modal>
    </>
  );
};
