import {
  Card,
  Title,
  CircularLoader,
  Input,
} from "@introist/react-foundation/v2";
import { AppPage } from "../../../AppPage";
import styled from "@emotion/styled";
import { useMemo, useState } from "react";
import { useTasks } from "../../../data/schema";
import { TaskItem } from "./TaskItem";
import { Header } from "./Header";
import { orderBy, uniq } from "lodash";

const DailyCard = styled(Card)`
  padding: var(--spacing-xxLarge);
  width: 700px;
  min-height: 400px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-large);
`;

const AddTaskInput = styled(Input)`
  ::placeholder {
    text-align: center;
  }
`;

const TaskListContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  margin-top: var(--spacing-large);
  gap: var(--spacing-large);
`;

export const DailyPage = () => {
  const [date, setDate] = useState(new Date());

  const { data: tasks, loading, save, update, remove } = useTasks();

  const dailyTasks = useMemo(() => {
    return orderBy(
      tasks.filter((task) => task.date === date.toDateString()),
      [(t) => t.completed, (t) => t.createdAt],
      ["asc", "asc"]
    );
  }, [tasks, date]);

  const topics = useMemo(() => {
    return uniq(
      (tasks ?? [])
        .map((task) => task.topic)
        .filter((t) => t !== undefined) as string[]
    );
  }, [tasks]);

  const renderTaskList = () => {
    return (
      <TaskListContainer>
        {!loading && dailyTasks.length === 0 && (
          <Title style={{ margin: "auto" }}>No tasks for the day</Title>
        )}
        {dailyTasks.map((task) => (
          <TaskItem
            key={task.id}
            task={task}
            onTaskChanged={async (changed) => {
              await save(changed);
            }}
            onRemove={async () => {
              await remove(task.id);
            }}
            onComplete={async () => {
              await update(task.id, { completed: !task.completed });
            }}
            topics={topics}
          />
        ))}

        <AddTaskInput
          style={{ marginTop: "auto" }}
          type="text"
          variant="blended"
          placeholder="What's worth doing well this day?"
          onKeyUp={async (event) => {
            if (event.key === "Enter") {
              save({
                date: date.toDateString(),
                title: event.currentTarget.value,
                completed: false,
                criteria: [],
              });

              event.currentTarget.value = "";
            }
          }}
        />
      </TaskListContainer>
    );
  };

  return (
    <AppPage>
      <DailyCard elevated>
        <Header date={date} onChangeDate={setDate} />
        {loading && <CircularLoader fillParent style={{ flex: 1 }} />}
        {!loading && renderTaskList()}
      </DailyCard>
    </AppPage>
  );
};
