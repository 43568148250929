import { getAuth, User } from "firebase/auth";
import { app } from "./firebase";
import { useCallback, useEffect, useState } from "react";

export const useUser = () => {
  const [user, setUser] = useState<User | null | undefined>();

  useEffect(() => {
    return getAuth(app).onAuthStateChanged((user) => {
      if (user === null) window.location.href = "/";
      setUser(user);
    });
  }, []);

  const logout = useCallback(async () => {
    await getAuth(app).signOut();
    window.location.href = "/";
  }, []);

  return { user, logout };
};
