import { H2, Title, Button } from "@introist/react-foundation/v2";
import styled from "@emotion/styled";

import HeroImg from "./hero.png";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { SignInButton } from "./SignIn";
import { useUser } from "../../../data/firebase/use-user";

const Page = styled.div`
  max-width: 960px;
  margin: auto;
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 0;
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xLarge);
  justify-content: center;
  margin: var(--spacing-xLarge);
`;

const Centered = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HomePage = () => {
  const navigate = useNavigate();

  const isDesktop = useMemo(() => {
    const query = `(min-width: 960px)`;
    return window.matchMedia(query).matches;
  }, []);

  const user = useUser();

  useEffect(() => {
    if (user) navigate("/daily");
  }, [user, navigate]);

  return (
    <Page>
      <Header>
        <SignInButton />
      </Header>
      <Centered style={{ minHeight: "100vh" }}>
        <HeroContent>
          <H2>Yet Another Task</H2>
          <Title>We all do tasks every day, that's how to make progress.</Title>
          <Title>
            Yet Another Task helps you to mindfully select the tasks that are
            worth doing and encourage you to enjoy the rest of the day when
            done.
          </Title>
          <Title>Because life is not only about tasks.</Title>

          <Button
            size="large"
            style={{ marginTop: "32px" }}
            onClick={() => {
              window.open("https://getwaitlist.com/waitlist/7946", "_blank");
            }}
          >
            Join waitlist
          </Button>

          <div
            style={{
              marginTop: "32px",
              display: "flex",
              gap: "8px",
            }}
          >
            <Title>Not convinced yet?</Title>
            <Title>
              <Link
                to="https://medium.com/@naavoapps/why-yet-another-task-list-27c3c7e3af56"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                Read why
              </Link>
            </Title>
            <Title>
              <Link
                to="https://medium.com/@naavoapps/how-might-we-build-yet-another-task-list-46bbff998f3"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                Read how
              </Link>
            </Title>
          </div>
        </HeroContent>
        {isDesktop && (
          <Centered>
            <img
              src={HeroImg}
              alt="hero"
              style={{ width: "400px", height: "400px", background: "blue" }}
            />
          </Centered>
        )}
      </Centered>
    </Page>
  );
};
