import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDa0rlBqWDhfCteVhhWa1AEyV1ZbL1RuF4",
  authDomain: "yetanothertask.com",
  projectId: "yetanothertask-1566f",
  storageBucket: "yetanothertask-1566f.appspot.com",
  messagingSenderId: "105583963944",
  appId: "1:105583963944:web:126117d7394734ab7b2a1d",
  measurementId: "G-MRX87W1WCN",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
