import { ZodSchema, ZodTypeAny } from "zod";
import {
  setDoc,
  updateDoc,
  deleteDoc,
  doc,
  DocumentSnapshot,
  getDoc,
  onSnapshot,
  query,
} from "firebase/firestore";
import { db, getUserCollection } from "./firestore";

const zodConverter = (schema: ZodSchema<any>) => ({
  toFirestore: (data: any) => schema.parse(data),
  fromFirestore: (snapshot: DocumentSnapshot) => schema.parse(snapshot.data()),
});

export const findUserEntry =
  <ItemType extends ZodTypeAny>(schema: ZodSchema<ItemType>, path: string[]) =>
  async (docId: string): Promise<ItemType> => {
    const docRef = doc(db, getUserCollection(path).path, docId).withConverter(
      zodConverter(schema)
    );
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data() as ItemType;
    } else {
      throw new Error(
        `Document at path ${path} with id ${docId} does not exist`
      );
    }
  };

export const saveUserEntry =
  (schema: ZodSchema<any>, path: string[]) =>
  async (data: any): Promise<string> => {
    const validated = schema.parse(data);
    if (!validated.id) throw new Error("Saved entry must have an id");

    const docRef = doc(db, getUserCollection(path).path, validated.id);

    await setDoc(docRef, validated);
    return docRef.id;
  };

export const updateUserEntry =
  (schema: ZodSchema<any>, path: string[]) =>
  async (id: string, updates: any): Promise<string> => {
    const docRef = doc(db, getUserCollection(path).path, id).withConverter(
      zodConverter(schema)
    );

    await updateDoc(docRef, updates);
    return findUserEntry(schema, path)(docRef.id);
  };

export const removeUserEntry =
  (schema: ZodSchema<any>, path: string[]) =>
  async (id: string): Promise<void> => {
    const docRef = doc(db, getUserCollection(path).path, id).withConverter(
      zodConverter(schema)
    );
    await deleteDoc(docRef);
  };

export const monitorUserEntries =
  <ItemType extends ZodTypeAny>(schema: ItemType, path: string[]) =>
  (callback: (data: ItemType[]) => void) => {
    const q = query(
      getUserCollection(path).withConverter(zodConverter(schema))
    );
    return onSnapshot(q, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => doc.data());
      callback(data);
    });
  };

export const zodStore = <ItemType extends ZodTypeAny>(
  schema: ItemType,
  path: string[]
) => ({
  monitor: monitorUserEntries(schema, path),
  find: findUserEntry(schema, path),
  save: saveUserEntry(schema, path),
  update: updateUserEntry(schema, path),
  remove: removeUserEntry(schema, path),
});
export type ZodStore = ReturnType<typeof zodStore>;
