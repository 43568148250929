import {
  IconButton,
  Input,
  Title,
  useTheme,
} from "@introist/react-foundation/v2";
import styled from "@emotion/styled";
import { TaskCriterion } from "../../../data/schema";

type Props = {
  criteria: TaskCriterion[];
  onCriteriaChanged: (criteria: TaskCriterion[]) => void;
  taskCompleted?: boolean;
};

const CriteriaContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);

  input::placeholder {
    visibility: hidden;
  }

  :hover input::placeholder {
    visibility: visible;
  }

  :hover .remove-criterion {
    display: initial !important;
  }
`;

const CriteriaItem = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-small);
`;

export const TaskCriteria = ({
  criteria,
  onCriteriaChanged,
  taskCompleted,
}: Props) => {
  const { theme } = useTheme();

  return (
    <CriteriaContainer>
      {criteria.map((criterion) => (
        <CriteriaItem>
          <div style={{ width: "34px" }}>
            <IconButton
              icon="check"
              style={{ display: taskCompleted ? "none" : undefined }}
              color={
                criterion.completed || taskCompleted
                  ? theme.palette.success
                  : theme.palette.border
              }
              onClick={() => {
                onCriteriaChanged(
                  criteria.map((c) => {
                    if (c.title === criterion.title)
                      return {
                        ...criterion,
                        completed: !c.completed,
                      };
                    return c;
                  })
                );
              }}
            />
          </div>
          <Title
            style={{
              fontSize: "12px",
              lineHeight: "24px",
              outline: "none",
              textDecoration: criterion.completed ? "line-through" : undefined,
            }}
            color={taskCompleted ? theme.palette.success.default : undefined}
            contentEditable={true}
            onBlur={(e) =>
              onCriteriaChanged(
                criteria.map((c) => {
                  if (c.title === criterion.title)
                    return {
                      title: e.currentTarget.innerText,
                      completed: c.completed,
                    };
                  return c;
                })
              )
            }
          >
            {criterion.title}
          </Title>

          <IconButton
            className="remove-criterion"
            icon="crossSmall"
            color={theme.palette.border}
            style={{ marginLeft: "auto", display: "none" }}
            onClick={() => {
              onCriteriaChanged(criteria.filter((c) => c !== criterion));
            }}
          />
        </CriteriaItem>
      ))}
      <Input
        style={{ marginLeft: "22px", border: "none" }}
        inputStyles={{ fontSize: "12px" }}
        type="text"
        variant="blended"
        size="small"
        placeholder="What does doing well mean?"
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            onCriteriaChanged([
              ...criteria,
              { title: e.currentTarget.value, completed: false },
            ]);
            e.currentTarget.value = "";
          }
        }}
      />
    </CriteriaContainer>
  );
};
